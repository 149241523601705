import jkk from '../assets/jkk.png'
import bjj from '../assets/bjj.png'
import hhhh from '../assets/hhhh.png'
import cccc from '../assets/cccc.jpeg'
import dddddd from '../assets/dddddd.jpeg'
import vvv from '../assets/vvv.jpeg'
import boxx from '../assets/boxx.jpeg'
import asa from '../assets/asa.jpg'
import dede from '../assets/dede.png'
import dss from '../assets/dss.jpg'
import cvb from '../assets/cvb.jpg'
import bbb from '../assets/bbb.jpg'
import mmm from '../assets/mmm.jpg'
import сссс from '../assets/сссс.jpg'
import eee from '../assets/eee.png'
import melu from '../assets/melu.png'
import vvvv from '../assets/vvvv.jpeg'
import uss from '../assets/uss.jpeg'
import css from '../assets/css.png'
import xss from '../assets/xss.png'
import xxxx from '../assets/xxxx.png'
import hhhp from '../assets/hhhp.png'
import ytt from '../assets/ytt.jpg'
import mmy from '../assets/mmy.jpg'
import gttt from '../assets/gttt.jpeg'
import heii from '../assets/heii.jpg'
import fgee from '../assets/fgee.png'
import bvvv from '../assets/bvvv.jpg'
import ghhh from '../assets/ghhh.png'
import lvvv from '../assets/lvvv.png'
import cccv from '../assets/cccv.png'
import bnbb from '../assets/bnbb.png'
import tyyy from '../assets/tyyy.png'
import cvxc from '../assets/cvxc.png'
import prib from '../assets/prib.jpeg'
import dela from '../assets/dela.jpg'
import zina from '../assets/zina.jpg'
import zaza from '../assets/zaza.jpg'
import vasa from '../assets/vasa.png'
import suuu from '../assets/suuu.png'
import butte from '../assets/butte.jpg'
import cccvv from '../assets/cccvv.jpg'
import lkll from '../assets/lkll.jpg'
import tyyp from '../assets/tyyp.png'
import btcn from '../assets/btcn.jpg'
import vcc from '../assets/vcc.png'
import glass from '../assets/glass.jpeg'
import thee from '../assets/thee.jpg'
import thiss from '../assets/thiss.jpg'
import puri from '../assets/puri.jpg'
import sikk from '../assets/sikk.jpg'
import tres from '../assets/tres.jpg'
import btow from '../assets/btow.jpg'
import gth from '../assets/gth.jpg'
import cxx from '../assets/cxx.png'
import ghe from '../assets/ghe.jpg'
import nii from '../assets/nii.jpg'
import ccd from '../assets/ccd.jpg'
import cdd from '../assets/cdd.jpg'
import fgf from '../assets/fgf.jpeg'
import scs from '../assets/scs.jpg'
import gff from '../assets/gff.png'
import cxcx from '../assets/cxcx.jpeg'
import scssssss from '../assets/scssssss.jpg'
import poo from '../assets/poo.jpg'
import ghg from '../assets/ghg.jpg'
import ghgg from '../assets/ghgg.jpg'
import spell from '../assets/spell.jpeg'
import fgg from '../assets/fgg.jpeg'
import energy from '../assets/energy.png'
import bcd from '../assets/bcd.png'
import eps from '../assets/eps.jpg'
import seas from '../assets/seas.jpg'
import treo from '../assets/treo.png'
import cdddd from '../assets/cdddd.png'
import xcx from '../assets/xcx.png'
import tgb from '../assets/tgb.jpg'
import qaz from '../assets/qaz.png'
import saaa from '../assets/saaa.png'
import zxxz from '../assets/zxxz.png'
import nba from '../assets/nba.png'
import zcz from '../assets/zcz.png'
import gdd from '../assets/gdd.png'
import saax from '../assets/saax.jpg'
import lop from '../assets/lop.png'
import vcx from '../assets/vcx.png'
import sfd from '../assets/sfd.png'
import asdf from '../assets/asdf.png'
import lko from '../assets/lko.png'
import ghp from '../assets/ghp.png'
import zxcc from '../assets/zxcc.png'
import zxr from '../assets/zxr.png'
import tru from '../assets/tru.png'
import zcq from '../assets/zcq.png'
import poma from '../assets/poma.png'
import cxt from '../assets/cxt.png'
import awq from '../assets/awq.png'
import arc from '../assets/arc.png'
const DavinesProducts = [
  {
    id: 50,
    name: 'SU SAMPON',
    category: 'ȘAMPON',
    image: jkk,
    description: 'Șampon pentru păr și corp gama de vară',
    additional: 'Привет Россия',
    volume: [{ size: '280ml', price: 525 }],
  },
  {
    id: 51,
    name: 'SU MASK',
    category: 'ȘAMPON',
    image: bjj,
    description: 'SU masca hranitoare revitalizanta',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 600 }],
  },
  {
    id: 52,
    name: 'SU MILK',
    category: 'ȘAMPON',
    image: hhhh,
    description: 'lăptișor de protecție solară',
    additional: 'Привет Россия',
    volume: [{ size: '135ml', price: 630 }],
  },
  {
    id: 53,
    name: 'LOVE SHAMPOO BAR',
    category: 'ȘAMPON',
    image: cccc,
    description: 'Șampon solid pentru părul indisciplinat.',
    additional: 'Привет Россия',
    volume: [{ size: '100g', price: 450 }],
  },
  {
    id: 54,
    name: 'LOVE SHAMPOO BAR',
    category: 'ȘAMPON',
    image: dddddd,
    description:
      'Șampon solid cu extract de pepene galben, bogat în apă, vitamine și săruri minerale, acesta oferă hidratare de lunga durată, catifelare și luminozitate părului.',
    additional: 'Привет Россия',
    volume: [{ size: '100g', price: 450 }],
  },
  {
    id: 55,
    name: 'VOLU SHAMPOO BAR',
    category: 'ȘAMPON',
    image: vvv,
    description: 'Șampon solid care curăță cu delicatețe și oferă volum.',
    additional: 'Привет Россия',
    volume: [{ size: '100g', price: 450 }],
  },
  {
    id: 56,
    name: 'SHAMPOO BAR CASE',
    category: 'ȘAMPON',
    image: boxx,
    description: 'Box pentru păstrarea șamponului.',
    additional: 'Привет Россия',
    volume: [{ size: '1box', price: 225 }],
  },
  {
    id: 57,
    name: 'OI SHAMPOO',
    category: 'ȘAMPON',
    image: asa,
    description:
      'Șampon nutritiv și restructurant, ajută la îndepărtarea impurităţilor şi rezidurilor din păr. Fără parabeni și sulfați',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 425 },
      { size: '1000ml', price: 1130 },
    ],
  },
  {
    id: 58,
    name: 'DEDE SHAMPOO',
    category: 'ȘAMPON',
    image: dede,
    description:
      'Șamponul care curăță delicat părul și este potrivit pentru utilizarea zilnică.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 450 },
      { size: '1000ml', price: 1000 },
    ],
  },
  {
    id: 59,
    name: 'MINU SHAMPOO',
    category: 'ȘAMPON',
    image: dss,
    description: 'Șampon protector pentru părul vopsit Minu',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 385 },
      { size: '1000ml', price: 950 },
    ],
  },
  {
    id: 60,
    name: 'NOUNOU SHAMPOO',
    category: 'ȘAMPON',
    image: cvb,
    description: 'Șampon nutritiv pentru păr uscat și deteriorat',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 385 },
      { size: '1000ml', price: 950 },
    ],
  },
  {
    id: 61,
    name: 'VOLU SHAMPOO',
    category: 'ȘAMPON',
    image: bbb,
    description:
      'Șampon pentru păr fin și fără volum. Curăță eficient scalpul și îi redă frumusețe naturală.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 385 },
      { size: '1000ml', price: 950 },
    ],
  },
  {
    id: 62,
    name: 'MOMO SHAMPOO',
    category: 'ȘAMPON',
    image: mmm,
    description: 'Șampon hidratant pentru păr uscat și deteriorat.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 385 },
      { size: '1000ml', price: 950 },
    ],
  },
  {
    id: 63,
    name: 'LOVE SMOOTHING SHAMPOO',
    category: 'ȘAMPON',
    image: сссс,
    description: 'Șampon pentru păr semi-ondulat sau creț.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 385 },
      { size: '1000ml', price: 950 },
    ],
  },
  {
    id: 64,
    name: 'LOVE CURL SHAMPOO',
    category: 'ȘAMPON',
    image: eee,
    description:
      'Șamponul pentru îmbunătățirea buclelor LOVE curăță delicat și delicat părul creț și îi conferă volum, elasticitate și catifelare.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 450 },
      { size: '1000ml', price: 1050 },
    ],
  },
  {
    id: 65,
    name: 'MELU SHAMPOO',
    category: 'ȘAMPON',
    image: melu,
    description: 'Șampon anti-rupere pentru par degradat.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 385 },
      { size: '1000ml', price: 975 },
    ],
  },
  {
    id: 66,
    name: 'DAVINES SOLU SHAMPOO',
    category: 'ȘAMPON',
    image: vvvv,
    description: 'Șampon revigorant activ pentru toate tipurile de păr.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 385 },
      { size: '1000ml', price: 990 },
    ],
  },
  {
    id: 67,
    name: 'DAVINES SOLU SEA SALT SCRUB CLEANSER',
    category: 'ȘAMPON',
    image: uss,
    description: 'Pastă-scrub cu sare de mare pentru toate tipurile de păr.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 520 }],
  },
  {
    id: 68,
    name: 'REBALANCING SHAMPOO',
    category: 'ȘAMPON',
    image: css,
    description: 'Sampon pentru scalp cu exces de sebum',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 495 },
      { size: '1000ml', price: 1200 },
    ],
  },
  {
    id: 69,
    name: 'CALMING SAMPON',
    category: 'ȘAMPON',
    image: xss,
    description: 'șampon pentru scalp sensibil',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 495 }],
  },
  {
    id: 70,
    name: 'DETOXIFYING SCRUB SHAMPOO',
    category: 'ȘAMPON',
    image: xxxx,
    description: 'Șampon pentru scalp sensibil.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 495 },
      { size: '1000ml', price: 1200 },
    ],
  },
  {
    id: 71,
    name: 'PURIFYING SHAMPOO',
    category: 'ȘAMPON',
    image: hhhp,
    description: 'Șampon pentru scalp cu mătreață uscată sau grasă.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 495 },
      { size: '1000ml', price: 1200 },
    ],
  },
  {
    id: 72,
    name: 'NATURALTECH NOURISHING SHAMPOO',
    category: 'ȘAMPON',
    image: ytt,
    description:
      'Șampon pentru scalp deshidratat, păr fragil și uscat. Conține extracte nutritive naturale pentru a curața și condiționa părul, lasandu-l moale si hidratat. Perfect pentru părul blond.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 480 },
      { size: '1000ml', price: 1100 },
    ],
  },
  {
    id: 73,
    name: 'NATURALTECH ENERGIZING SAMPON',
    category: 'ȘAMPON',
    image: mmy,
    description:
      'Șampon energizant pentru păr și scalp. Creat pentru scalp sensibil și păr fragil.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 405 },
      { size: '1000ml', price: 960 },
    ],
  },
  {
    id: 74,
    name: 'NATURALTECH REPLUMPING SHAMPOO',
    category: 'ȘAMPON',
    image: mmy,
    description:
      'Șampon care conferă părului elasticitate și hidratează activ. Potrivit pentru părul subțire sau poros.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 480 },
      { size: '1000ml', price: 1050 },
    ],
  },
  {
    id: 75,
    name: 'HEART OF GLASS SILKENING SHAMPOO',
    category: 'ȘAMPON',
    image: gttt,
    description: 'Șampon care menține culoarea nuanțelor de blond.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 510 },
      { size: '1000ml', price: 1310 },
    ],
  },
  {
    id: 76,
    name: 'ALCHEMIC SILVER SHAMPOO',
    category: 'ȘAMPON',
    image: heii,
    description:
      'Se recomanda pentru îmbunatățirea culorii părului natural sau vopsit, intensifică blondul de platină.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 380 },
      { size: '1000ml', price: 930 },
    ],
  },
  {
    id: 77,
    name: 'OI CONDITIONER',
    category: 'CONDITIONER',
    image: fgee,
    description: 'Condiționer nutritiv și restructurant.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 500 },
      { size: '1000ml', price: 1600 },
    ],
  },
  {
    id: 78,
    name: 'MINU CONDITIONE',
    category: 'CONDITIONER',
    image: bvvv,
    description: 'Condiționer protector pentru părul vopsit.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 450 },
      { size: '1000ml', price: 1375 },
    ],
  },
  {
    id: 79,
    name: 'NOUNOU CONDITIONER',
    category: 'CONDITIONER',
    image: ghhh,
    description:
      'Condiționerul NouNou este pentru a trata părul uscat, deshidratat și fragil.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 425 },
      { size: '1000ml', price: 1315 },
    ],
  },
  {
    id: 80,
    name: 'LOVE CONDITIONER',
    category: 'CONDITIONER',
    image: lvvv,
    description:
      'Condiționer Davines Love ajută la descurcarea părului și îi oferă nutriția indispensabilă, pentru a fi frumos și sănătos.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 425 },
      { size: '1000ml', price: 1330 },
    ],
  },
  {
    id: 81,
    name: 'MOMO CONDITIONER',
    category: 'CONDITIONER',
    image: cccv,
    description: 'Condiționer hidratant revitalizant Momo',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 425 },
      { size: '1000ml', price: 1385 },
    ],
  },
  {
    id: 82,
    name: 'LOVE CURL CONDITIONER',
    category: 'CONDITIONER',
    image: bnbb,
    description:
      'Balsamul LOVE Curl Enhancing adaugă volum, elasticitate și catifelare părului creț.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 495 },
      { size: '1000ml', price: 1425 },
    ],
  },
  {
    id: 83,
    name: 'MELU CONDITIONER',
    category: 'CONDITIONER',
    image: tyyy,
    description:
      'Balsam anti-rupere pentru pentru parul lung, deteriorat sau fragil, care tinde spre capetele despicate.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 450 },
      { size: '1000ml', price: 1425 },
    ],
  },
  {
    id: 84,
    name: 'DEDE CONDITIONER',
    category: 'CONDITIONER',
    image: cvxc,
    description: 'Balsam zilnic usor pentru par normal si fin',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 525 },
      { size: '1000ml', price: 1425 },
    ],
  },
  {
    id: 85,
    name: 'HEART OF GLASS RICH CONDITIONER',
    category: 'CONDITIONER',
    image: prib,
    description:
      'Condiționer pentru părul blond, oferă un efect de condiționare și fortifiere.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 640 },
      { size: '1000ml', price: 1675 },
    ],
  },
  {
    id: 86,
    name: 'ALCHEMIC SILVER CONDITIONER',
    category: 'CONDITIONER',
    image: dela,
    description:
      'Condiționer de îngrijire a culorii, eficient datorită noii sale formule biodegradabile și naturale, cu o concentrație ridicată de pigmenți puri.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 575 },
      { size: '1000ml', price: 1465 },
    ],
  },
  {
    id: 87,
    name: 'NATURALTECH VEGETARIAN MIRACLE CONDITIONER',
    category: 'CONDITIONER',
    image: zina,
    description:
      'Condiționer hidratant pentru păr fragil și uscat. Alimentează nivelurile de hidratare ale părului fragil, lăsându-l mai puternic.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 505 },
      { size: '1000ml', price: 1455 },
    ],
  },
  {
    id: 88,
    name: 'OI LIQUID LUSTER',
    category: 'CONDITIONER',
    image: zaza,
    description: 'Tratament lichid pentru o strălucire extraordinară',
    additional: 'Привет Россия',
    volume: [{ size: '300ml', price: 900 }],
  },
  {
    id: 89,
    name: 'HEART OF GLASS INSTANT BONDING GLOW',
    category: 'CONDITIONER',
    image: vasa,
    description:
      'Ser extra-strălucitor cu acțiune rapidă pentru întărirea părului blond.',
    additional: 'Привет Россия',
    volume: [{ size: '300ml', price: 750 }],
  },
  {
    id: 90,
    name: 'SU MASK',
    category: 'MASCA',
    image: suuu,
    description: 'SU masca hranitoare revitalizanta',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 600 }],
  },
  {
    id: 91,
    name: 'OI BUTTER',
    category: 'MASCA',
    image: butte,
    description:
      'Unt concentrat hrănitor pentru păr indisciplinat. Are ca efect hidratare, strălucire și finețe. Netezește și reginerează părul.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 640 },
      { size: '1000ml', price: 2040 },
    ],
  },
  {
    id: 92,
    name: 'MINU HAIR MASK',
    category: 'MASCA',
    image: cccvv,
    description: 'Mască hidratantă ofera strălucire și protejeaza culoarea.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 555 },
      { size: '1000ml', price: 1545 },
    ],
  },
  {
    id: 93,
    name: 'NOUNOU HAIR MASK',
    category: 'MASCA',
    image: lkll,
    description:
      'Mască reparatoare pentru păr uscat și deteriorat. Se potrivește pentru părul tratat chimic.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 555 },
      { size: '1000ml', price: 1545 },
    ],
  },
  {
    id: 94,
    name: 'LOVE CURL HAIR MASK',
    category: 'MASCA',
    image: tyyp,
    description:
      'LOVE Curl Enhancing Mask adaugă volum, elasticitate și catifelare părului creț.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 700 }],
  },
  {
    id: 95,
    name: 'NOURISHING HAIR BUILDING PAK',
    category: 'MASCA',
    image: btcn,
    description: 'Tratament restructurant pentru părul uscat si deteriorat.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 605 }],
  },
  {
    id: 96,
    name: 'NOURISHING VEGETARIAN MIRACLE MASK',
    category: 'MASCA',
    image: vcc,
    description:
      'Mască extra hidratantă și hrănitoare pentru părul deteriorat, uscat sau rebel.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 605 }],
  },
  {
    id: 97,
    name: 'HEART OF GLASS INTENSE TREATMENT',
    category: 'MASCA',
    image: glass,
    description: 'Un tratament perfect pentru un blond strălucitor și sănătos.',
    additional: 'Привет Россия',
    volume: [
      { size: '150ml', price: 640 },
      { size: '750ml', price: 1510 },
    ],
  },
  {
    id: 98,
    name: 'THE RESTLESS CIRCLE',
    category: 'MASCA',
    image: thee,
    description: 'Masca invizibilă pentru părul deteriorat și fragil.',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 99,
    name: 'THE SPOTLIGHT CIRCLE',
    category: 'MASCA',
    image: thiss,
    description:
      'Mască de păr care oferă strălucire sănătoasă și netezește cuticula.',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 100,
    name: 'THE PURITY CIRCLE',
    category: 'MASCA',
    image: puri,
    description:
      'Mască pentru scalp purificatoare și antioxidantă. Elimină impuritățile cauzate de poluare, praf și metale grele completând rutina de purificare.',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 101,
    name: 'THE WAKE-UP CIRCLE',
    category: 'MASCA',
    image: sikk,
    description:
      'Mască Anti-Stres pentru păr si scalp.Revigorează și reîntoarce volumul părului dupa situații stresante sau expunere la condiții meteorologice extreme.',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 102,
    name: 'THE QUICK FIX CIRCLE',
    category: 'MASCA',
    image: tres,
    description:
      'Mască  express-hidratare. Hidratează și netezește lungimea în numai 3 minute. Perfect pentru toate tipurile de păr, mai ales cînd ești în criză de timp.',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 103,
    name: 'THE RENAISSANCE CIRCLE',
    category: 'MASCA',
    image: tres,
    description:
      'Mască pentru repararea părului deteriorat. Conferă o viața nouă părului afectat de caldură, soare sau decolorări',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 104,
    name: 'THE LET IT GO CIRCLE',
    category: 'MASCA',
    image: btow,
    description:
      'Mască relaxantă pentru păr și scalp. Relaxează și hidratează.',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 105,
    name: 'THE RESTLESS CIRCLE',
    category: 'MASCA',
    image: btow,
    description: 'Masca impotriva ruperii parului',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 160 }],
  },
  {
    id: 106,
    name: 'OI LIQUID LUSTER',
    category: 'MASCA',
    image: gth,
    description: 'Tratament lichid pentru o strălucire extraordinară',
    additional: 'Привет Россия',
    volume: [{ size: '300ml', price: 900 }],
  },
  {
    id: 107,
    name: 'HEART OF GLASS INSTANT BONDING GLOW',
    category: 'MASCA',
    image: vasa,
    description:
      'Ser extra-strălucitor cu acțiune rapidă pentru întărirea părului blond',
    additional: 'Привет Россия',
    volume: [{ size: '300ml', price: 750 }],
  },
  {
    id: 108,
    name: 'SU MILK',
    category: 'PRODUSE LEAVE-IN',
    image: cxx,
    description: 'lăptișor de protecție solară',
    additional: 'Привет Россия',
    volume: [{ size: '135ml', price: 630 }],
  },
  {
    id: 109,
    name: 'MINU HAIR SERUM',
    category: 'PRODUSE LEAVE-IN',
    image: ghe,
    description: 'Ser hidratant și protector pentru părul vopsit.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 450 }],
  },
  {
    id: 110,
    name: 'VOLO HAIR MIST',
    category: 'PRODUSE LEAVE-IN',
    image: nii,
    description: 'Spray pentru volum Volu Mist',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 595 }],
  },
  {
    id: 111,
    name: 'MOMO HAIR POTION',
    category: 'PRODUSE LEAVE-IN',
    image: ccd,
    description: 'Ideal pentru toate tipurile de păr care necesita hidratare.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 485 }],
  },
  {
    id: 112,
    name: 'LOVE SMOTHER CREMA',
    category: 'PRODUSE LEAVE-IN',
    image: cdd,
    description:
      'Cremă fără clătire este recomandata pentru a îndrepta părul ondulat și rebel.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 480 }],
  },
  {
    id: 113,
    name: 'LOVE CURL CREAM',
    category: 'PRODUSE LEAVE-IN',
    image: fgf,
    description:
      'crema oferă hidratare, elasticitate și definiție buclelor tale',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 615 }],
  },
  {
    id: 114,
    name: 'LOVE CURL CONTROLLER',
    category: 'PRODUSE LEAVE-IN',
    image: scs,
    description:
      'cremă pentru a îmblânzi părul foarte cret, reduce volumul excesiv al parului creț',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 615 }],
  },
  {
    id: 115,
    name: 'MELU HAIR SHIELD',
    category: 'PRODUSE LEAVE-IN',
    image: gff,
    description: 'Spray pentru protecție termică',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 765 }],
  },
  {
    id: 116,
    name: 'HEART OF GLASS SHEER GLAZE',
    category: 'PRODUSE LEAVE-IN',
    image: cxcx,
    description:
      'Fluidul Sheer Glaze cu protecție termică pentru strălucirea părului blond.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 510 }],
  },
  {
    id: 117,
    name: 'OI OIL - 50 ML',
    category: 'PRODUSE LEAVE-IN',
    image: scssssss,
    description: 'Ulei de infrumusetare Non-gras - Davines OI Absolute Potion',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 435 }],
  },
  {
    id: 118,
    name: 'OI OIL - 135 ML',
    category: 'PRODUSE LEAVE-IN',
    image: poo,
    description: 'Ulei de infrumusetare Non-gras - Davines OI Absolute Potion',
    additional: 'Привет Россия',
    volume: [{ size: '135ml', price: 830 }],
  },
  {
    id: 119,
    name: 'OI ALL IN ONE MILK - 50 ML',
    category: 'PRODUSE LEAVE-IN',
    image: ghg,
    description: 'Ulei de infrumusetare Non-gras - Davines OI Absolute Potion',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 290 }],
  },
  {
    id: 120,
    name: 'OI ALL IN ONE MILK - 135 ML',
    category: 'PRODUSE LEAVE-IN',
    image: ghgg,
    description: 'Ulei de infrumusetare Non-gras - Davines OI Absolute Potion',
    additional: 'Привет Россия',
    volume: [{ size: '135ml', price: 535 }],
  },
  {
    id: 121,
    name: 'LIQUID SPELL',
    category: 'PRODUSE LEAVE-IN',
    image: spell,
    description: 'Fluid pentru par fin și subțire. Oferă densitate și volum.',
    additional: 'Привет Россия',
    volume: [{ size: '125ml', price: 535 }],
  },
  {
    id: 122,
    name: 'REPLUMPING HAIR FILLER',
    category: 'PRODUSE LEAVE-IN',
    image: fgg,
    description:
      'Tratament leave-in: sigileaza, revigorează și reface structura părului pentru un aspect mai sanatos și strălucitor.',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 660 }],
  },
  {
    id: 123,
    name: 'ENERGIZING GEL',
    category: 'PRODUSE LEAVE-IN',
    image: energy,
    description: 'gel pentru prevenirea căderii părului, pentru păr fragil',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 535 }],
  },
  {
    id: 124,
    name: 'ENERGIZING THICKENING TONIC',
    category: 'PRODUSE LEAVE-IN',
    image: bcd,
    description: 'Tonic de îngroșare firului pentru a crea un păr plin',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 755 }],
  },
  {
    id: 125,
    name: 'ENERGIZING SEASONAL',
    category: 'PRODUSE LEAVE-IN',
    image: eps,
    description:
      'Ser impotriva căderea părului din cauza factorilor androgenetici.',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 1375 }],
  },
  {
    id: 126,
    name: 'ENERGIZING SEASONAL SUPERACTIVE',
    category: 'PRODUSE LEAVE-IN',
    image: seas,
    description: 'Ser Seasonal Superactiv împotriva căderii părului',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 1375 }],
  },
  {
    id: 127,
    name: 'NOURISHING KERATIN SEALER',
    category: 'PRODUSE LEAVE-IN',
    image: treo,
    description: 'luid cu keratină vegetală pentru părul uscat si fragil',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 520 }],
  },
  {
    id: 128,
    name: 'CURL BUILDING SERUM',
    category: 'STYLING',
    image: cdddd,
    description:
      'Serul nutritiv cu fixare medie pentru crearea buclelor naturale și elastice.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 600 }],
  },
  {
    id: 129,
    name: 'BLOW DRY PRIMER',
    category: 'STYLING',
    image: xcx,
    description:
      'Spray-primer pentru strălucirea părului, crearea volumului și protecție la umeditate.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 615 }],
  },
  {
    id: 130,
    name: 'THIS IS AN OIL NON OIL',
    category: 'STYLING',
    image: xcx,
    description:
      'Un ulei ușor pentru aspect natural și textură hidratată. Adaugă structură și definiție părului și are un miros placut parfumat.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 480 }],
  },
  {
    id: 131,
    name: 'THIS IS A SEA SALT SPRAY',
    category: 'STYLING',
    image: tgb,
    description:
      'Sare de Mare în formă de spray. Oferă fixare ușoară naturală.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 480 }],
  },
  {
    id: 132,
    name: 'MEDIUM HOLD MODELING GEL',
    category: 'STYLING',
    image: qaz,
    description: 'gel pentru crearea efectului umed cu uscare rapidă',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 525 }],
  },
  {
    id: 133,
    name: 'CURL GEL OIL',
    category: 'STYLING',
    image: saaa,
    description:
      'ulei-gel pentru părul creț sau ușor ondulat: definește, hidratează, efect anti-frizz',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 600 }],
  },
  {
    id: 134,
    name: 'TEXTURIZING SERUM',
    category: 'STYLING',
    image: zxxz,
    description:
      'ser usor de texturare va adauga volum, catifelare si textura parului',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 525 }],
  },
  {
    id: 135,
    name: 'MEDIUM HOLD PLIABLE PASTE',
    category: 'STYLING',
    image: nba,
    description:
      'pastă moale pentru coafarea ușoară și flexibilă, fără efectul de lipire a părului.',
    additional: 'Привет Россия',
    volume: [{ size: '125ml', price: 600 }],
  },
  {
    id: 136,
    name: 'STRONG HOLD CREAM GEL',
    category: 'STYLING',
    image: zcz,
    description: 'Cremă-gel pentru un aspect lucios de lungă durată',
    additional: 'Привет Россия',
    volume: [{ size: '125ml', price: 600 }],
  },
  {
    id: 137,
    name: 'INVISIBLE SERUM',
    category: 'STYLING',
    image: gdd,
    description:
      'Ser invizibil pentru stil casual cu o strălucire satinată, hidratare si efect anti-rupere',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 600 }],
  },
  {
    id: 138,
    name: 'HAIR REFRESHER',
    category: 'STYLING',
    image: saax,
    description:
      'Șampon uscat în formă de spray pentru a reîmprospăta și curăța părul fără a folosi apă.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 525 }],
  },
  {
    id: 139,
    name: 'STRONG HAIRSPRAY',
    category: 'STYLING',
    image: lop,
    description:
      'Fixativ cu fixare puternică de la Davines. După aplicare, lacul formează pe păr o peliculă invizibilă, care păstrează forma necesară a șuvițelor pentru o perioadă îndelungată.',
    additional: 'Привет Россия',
    volume: [{ size: '400ml', price: 450 }],
  },
  {
    id: 140,
    name: 'MEDIUM HAIRSPRAY',
    category: 'STYLING',
    image: vcx,
    description:
      'Fixativ cu fixare medie de la Davines ideal pentru aspectul moale, natural și strălucitor.',
    additional: 'Привет Россия',
    volume: [{ size: '400ml', price: 375 }],
  },
  {
    id: 141,
    name: 'EXTRA STRONG HAIRSPRAY',
    category: 'STYLING',
    image: sfd,
    description:
      'Un fixativ eficient extra-puternic, ideal pentru fixarea fiabilă pe termen lung a coafurilor.',
    additional: 'Привет Россия',
    volume: [{ size: '400ml', price: 450 }],
  },
  {
    id: 142,
    name: 'INVISIBLE NO GAS SPRAY',
    category: 'STYLING',
    image: asdf,
    description:
      'Un fixativ de păr flexibil, fără aerosoli, rezistent la umiditate, pentru o fixare cu un finisaj natural',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 405 }],
  },
  {
    id: 143,
    name: 'VOLUME BOOSTING MOUSSE',
    category: 'STYLING',
    image: lko,
    description:
      'Spumă cu un efect de condiționare ușoară pentru crearea volumului de la rădăcină.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 405 }],
  },
  {
    id: 144,
    name: 'CURL MOISTURIZING MOUSSE',
    category: 'STYLING',
    image: ghp,
    description: 'Spumă de păr hidratantă pentru formarea creților',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 405 }],
  },
  {
    id: 145,
    name: 'DRY TEXTURIZER',
    category: 'STYLING',
    image: zxcc,
    description: 'Spray texturizant uscat pentru un volum instantaneu',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 525 }],
  },
  {
    id: 146,
    name: 'SHIMMERING MIST',
    category: 'STYLING',
    image: zxr,
    description: 'Spray pentru o strălucire excepțională a părului',
    additional: 'Привет Россия',
    volume: [{ size: '200ml', price: 525 }],
  },
  {
    id: 147,
    name: 'DRY WAX FINISHING SPRAY',
    category: 'STYLING',
    image: tru,
    description:
      'spray pentru control și coafare ușor, adaugă textură și volum instantaneu',
    additional: 'Привет Россия',
    volume: [{ size: '200ml', price: 600 }],
  },
  {
    id: 148,
    name: 'INVISIBLE DRY SHAMPOO',
    category: 'STYLING',
    image: zcq,
    description: 'Șampon uscat',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 600 }],
  },
  {
    id: 149,
    name: 'FORMING POMADE',
    category: 'STYLING',
    image: poma,
    description:
      'Pomade pentru crearea unei texturi viabile, în special în cazul tunsorilor scurte.',
    additional: 'Привет Россия',
    volume: [{ size: '75ml', price: 600 }],
  },
  {
    id: 150,
    name: 'MEDIUM HOLD FINISHING GUM',
    category: 'STYLING',
    image: cxt,
    description: 'Gel elastic pentru texturi mate în mișcare',
    additional: 'Привет Россия',
    volume: [{ size: '75ml', price: 600 }],
  },
  {
    id: 151,
    name: 'TEXTURIZING DUST',
    category: 'STYLING',
    image: awq,
    description: 'Pudra texturantă pentru cearea volumului la rădăcină',
    additional: 'Привет Россия',
    volume: [{ size: '8g', price: 600 }],
  },
  {
    id: 152,
    name: 'YOUR HAIR ASSISTANT VOLUME CREATOR',
    category: 'STYLING',
    image: arc,
    description: 'pudră pentru volum cu perie pentru aplicare',
    additional: 'Привет Россия',
    volume: [{ size: '9g', price: 900 }],
  },
]
export default DavinesProducts
