// В файле App.js
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Davines from './pages/Davines'
import Nashi from './pages/Nashi'
import CartPage from './pages/CartPage'
import Header from './components/Header'

const App = () => {
  const [cartItems, setCartItems] = useState([])

  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems')
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems))
    }
  }, [])

  const handleAddToCart = (product) => {
    setCartItems((prevCartItems) => {
      const itemIndex = prevCartItems.findIndex(
        (item) =>
          item.id === product.id &&
          item.selectedVolumeIndex === product.selectedVolumeIndex
      )

      if (itemIndex >= 0) {
        const updatedCartItems = [...prevCartItems]
        updatedCartItems[itemIndex].quantity += 1
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems))
        return updatedCartItems
      } else {
        const updatedCartItems = [...prevCartItems, { ...product, quantity: 1 }]
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems))
        return updatedCartItems
      }
    })
  }

  const handleRemoveFromCart = (product) => {
    setCartItems((prevCartItems) => {
      const updatedCartItems = prevCartItems.filter(
        (item) =>
          !(
            item.id === product.id &&
            item.selectedVolumeIndex === product.selectedVolumeIndex
          )
      )
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems))
      return updatedCartItems
    })
  }

  const handleUpdateQuantity = (product, quantityChange) => {
    setCartItems((prevCartItems) => {
      const updatedCartItems = prevCartItems
        .map((item) => {
          if (
            item.id === product.id &&
            item.selectedVolumeIndex === product.selectedVolumeIndex
          ) {
            return { ...item, quantity: item.quantity + quantityChange }
          }
          return item
        })
        .filter((item) => item.quantity > 0)
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems))
      return updatedCartItems
    })
  }

  return (
    <Router>
      <div>
        <Header
          cartItems={cartItems}
          onRemoveFromCart={handleRemoveFromCart}
          onUpdateQuantity={handleUpdateQuantity}
        />
        <Routes>
          <Route
            path="/"
            element={<Home handleAddToCart={handleAddToCart} />}
          />
          <Route
            path="/davines"
            element={<Davines handleAddToCart={handleAddToCart} />}
          />
          <Route
            path="/nashi"
            element={<Nashi handleAddToCart={handleAddToCart} />}
          />
          <Route path="/cart" element={<CartPage cartItems={cartItems} />} />{' '}
          {/* Добавляем маршрут для страницы корзины */}
        </Routes>
      </div>
    </Router>
  )
}

export default App
