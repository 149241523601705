import React, { useState } from 'react'
import NashiProducts from '../data/NashiProducts'
import './Nashi.css'

const ProductModal = ({ product, handleClose, handleAddToCart }) => {
  const [selectedVolumeIndex, setSelectedVolumeIndex] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleVolumeChange = (index) => {
    setSelectedVolumeIndex(index)
    setDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const addToCart = () => {
    const productToAdd = { ...product, selectedVolumeIndex }
    handleAddToCart(productToAdd)
    handleClose()
  }

  return (
    <>
      <div className="overlay" onClick={handleClose}></div>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-image">
            <img src={product.image} alt={product.name} />
          </div>
          <div className="modal-details">
            <h2 className="product-title">{product.name}</h2>
            <p className="price">
              Price: {product.volume[selectedVolumeIndex].price} MDL
            </p>
            <div className="volume-dropdown">
              <div className="volume-header" onClick={toggleDropdown}>
                Volume: {product.volume[selectedVolumeIndex].size}{' '}
                <span className={`arrow ${dropdownOpen ? 'open' : ''}`}></span>
              </div>
              {dropdownOpen && (
                <div className="volume-dropdown-content">
                  {product.volume.map((volume, index) => (
                    <button
                      key={index}
                      onClick={() => handleVolumeChange(index)}
                    >
                      {volume.size}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <button onClick={addToCart} className="modal-button">
              Add to Cart
            </button>
            <p className="product-description">{product.description}</p>{' '}
          </div>
          <button onClick={handleClose} className="modal-close-btn">
            Close
          </button>
        </div>
      </div>
    </>
  )
}

const Nashi = ({ handleAddToCart }) => {
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleCategoryChange = (category) => {
    setSelectedCategory(category)
    setDropdownOpen(false) // Закрываем меню после выбора категории
  }

  const handleOpenModal = (product) => {
    setSelectedProduct(product)
  }

  const handleCloseModal = () => {
    setSelectedProduct(null)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const filteredProducts =
    selectedCategory === 'All'
      ? NashiProducts
      : NashiProducts.filter((product) => product.category === selectedCategory)

  return (
    <div>
      <div className="category-container">
        <button onClick={toggleDropdown} className="dropdown-toggle">
          {selectedCategory}
          <span className={`arrow ${dropdownOpen ? 'open' : ''}`}></span>
        </button>
        <div className={`category-buttons ${dropdownOpen ? 'open' : ''}`}>
          <button onClick={() => handleCategoryChange('All')}>All</button>
          <button onClick={() => handleCategoryChange('Hair')}>Hair</button>
          <button onClick={() => handleCategoryChange('Body')}>Body</button>
          <button onClick={() => handleCategoryChange('Styling')}>
            Styling
          </button>
          <button onClick={() => handleCategoryChange('Men')}>Men</button>
          <button onClick={() => handleCategoryChange('BOX SETS')}>
            BOX SETS
          </button>
        </div>
      </div>
      <div className="products-container">
        {filteredProducts.map((product) => (
          <div
            key={product.id}
            className="product"
            onClick={() => handleOpenModal(product)}
          >
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            {product.volume[0] && (
              <p className="price">Price: {product.volume[0].price} MDL</p>
            )}
          </div>
        ))}
      </div>
      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          handleClose={handleCloseModal}
          handleAddToCart={handleAddToCart}
        />
      )}
    </div>
  )
}

export default Nashi
