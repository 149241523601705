import React, { useState } from 'react'
import './CartPage.css'

const CartPage = ({ cartItems }) => {
  const [fullName, setFullName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const handleFullNameChange = (e) => {
    setFullName(e.target.value)
  }

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = { fullName, phoneNumber, cartItems }

    fetch('https://yellowboutique.md/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then((result) => {
        setMessage('Email trimis cu succes!')
        setError('')
        console.log('Email sent successfully:', result)
      })
      .catch((error) => {
        setError('Eroare la trimiterea datelor')
        setMessage('')
        console.error('Error:', error)
      })
  }

  return (
    <div className="cart-page-container">
      <div className="checkout-form">
        <h2>Introduceți datele dvs.</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Numele și prenumele</label>
            <input
              type="text"
              id="fullName"
              value={fullName}
              onChange={handleFullNameChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Numărul de contact</label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>
          <p>
            <button type="submit">verifică.</button>
          </p>
        </form>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
      <div className="cart-items-container">
        {cartItems.map((item, index) => (
          <div key={index} className="cart-item">
            <img src={item.image} alt={item.name} />
            <div className="item-details">
              <p>
                Preț:{' '}
                {item.quantity * item.volume[item.selectedVolumeIndex].price}{' '}
                MDL
              </p>
              <p>Volum: {item.volume[item.selectedVolumeIndex].size}</p>
              <p>Cantitate: {item.quantity}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CartPage
