import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './Header.css'
import yellowbt from '../assets/yellowbt.png'
import instagram from '../assets/instagram.png'
import shoppingcart from '../assets/SHPC1.png'

const Header = ({ cartItems, onRemoveFromCart, onUpdateQuantity }) => {
  const [isCartOpen, setIsCartOpen] = useState(false)
  const cartRef = useRef()

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setIsCartOpen(false)
      }
    }

    if (isCartOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isCartOpen])

  const totalAmount = cartItems.reduce((total, item) => {
    return total + item.quantity * item.volume[item.selectedVolumeIndex].price
  }, 0)

  const closeCart = () => {
    setIsCartOpen(false)
  }

  return (
    <header className="header-container">
      <div className="social-container">
        <a
          href="https://www.instagram.com/yellow_boutique.md?igsh=MWh6cGRobmRxd2M5bA=="
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} alt="Instagram" className="instagram-logo" />
        </a>
      </div>

      <div className="logo-container">
        <Link to="/">
          <img src={yellowbt} alt="Магазин косметики" className="logo" />
        </Link>
      </div>

      <div className="cart-container">
        <button className="cart-icon" onClick={toggleCart}>
          <img src={shoppingcart} alt="Coș" className="cart-image" /> (
          {cartItems.length})
        </button>
        {isCartOpen && (
          <div className="cart-menu" ref={cartRef}>
            <span className="close-icon" onClick={closeCart}>
              &#10006;
            </span>
            <div className="cart-menu-header">
              <h3>Coș</h3>
            </div>
            <div className="cart-menu-items">
              {cartItems.length === 0 ? (
                <p>Coșul de cumpărături este gol.</p>
              ) : (
                cartItems.map((item, index) => (
                  <div key={index} className="cart-menu-item">
                    <img src={item.image} alt={item.name} />
                    <div>
                      <p>{item.name}</p>
                      <p>
                        Preț:{' '}
                        {item.quantity *
                          item.volume[item.selectedVolumeIndex].price}{' '}
                        MDL
                      </p>
                      <p>Volum: {item.volume[item.selectedVolumeIndex].size}</p>
                      <p>Cantitate: {item.quantity}</p>
                      <div className="cart-item-buttons">
                        <button onClick={() => onUpdateQuantity(item, -1)}>
                          -
                        </button>
                        <button onClick={() => onUpdateQuantity(item, 1)}>
                          +
                        </button>
                        <span
                          className="delete-icon"
                          onClick={() => onRemoveFromCart(item)}
                        >
                          &#10006;
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="cart-menu-total">
              <strong>Total plătibil:</strong> {totalAmount} MDL
            </div>
            <div>
              <Link to="/cart" onClick={closeCart}>
                Următorul
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
