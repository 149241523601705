import SampooClassicImage from '../assets/SampooClassic.jpg'
import conditioner from '../assets/conditioner.jpg'
import instant from '../assets/instant.jpg'
import oil from '../assets/oil.jpg'
import capyxal from '../assets/capyxal.jpg'
import ser from '../assets/ser.png'
import pr from '../assets/pr.png'
import armonia from '../assets/shampoo-armonia-prod.png'
import armoniascr from '../assets/armoniascr.png'
import mass from '../assets/mass.png'
import masssol from '../assets/mass-solutionr-prod.png'
import filler from '../assets/filler.png'
import rest from '../assets/restorative-conditio.png'
import g1 from '../assets/g1adgy5rvbko.jpg'
import deep from '../assets/deep-infusion-4.jpg'
import resti from '../assets/res_b58e2760b785945f.jpg'
import filler2 from '../assets/61UdOSkcKoL.jpg'
import blondy from '../assets/hairfusion_claudia_h.jpg'
import purple from '../assets/blondy_joy_purple_co.jpg'
import ds from '../assets/ds.png'
import love from '../assets/love-hair-mist-3.jpg'
import wqe from '../assets/wqe.png'
import asd from '../assets/asd.png'
import scr from '../assets/scr.png'
import dry from '../assets/dry.png'
import day from '../assets/day.png'
import sce from '../assets/sce.png'
import bal from '../assets/bal.png'
import han from '../assets/han.png'
import lip from '../assets/lip.png'
import oel from '../assets/oel.png'
import cur from '../assets/cur.png'
import images from '../assets/images.jpeg'
import sadd from '../assets/sadd.jpg'
import ener from '../assets/ener.png'
import geler from '../assets/geler.png'
import saw from '../assets/saw.png'
import cvv from '../assets/cvv.png'
import ksiu from '../assets/ksiu.png'
import vara from '../assets/vara.png'

const NashiProducts = [
  {
    id: 1,
    name: 'ȘAMPOO CLASSIC',
    category: 'Hair',
    image: SampooClassicImage,
    description: 'pentru toate tipurile de par',
    additional: 'Привет Россия',
    volume: [
      { size: '200ml', price: 450 },
      { size: '500ml', price: 810 },
      { size: '1000ml', price: 1230 },
    ],
  },
  {
    id: 2,
    name: 'CONDIȚIONER CLASIC',
    category: 'Hair',
    image: conditioner,
    description: 'condiționer pentru toate tipurile de păr',
    additional: 'Привет Россия',
    volume: [
      { size: '200ml', price: 450 },
      { size: '400ml', price: 810 },
      { size: '1000ml', price: 1230 },
    ],
  },
  {
    id: 3,
    name: 'INSTANT NASHI ARGAN',
    category: 'Hair',
    image: instant,
    description:
      'mască leave-in în formă de spray. Părul devine mătăsos, strălucitor și ușor de pieptănat',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 580 }],
  },
  {
    id: 4,
    name: 'ULEI NASHI ARGAN',
    category: 'Hair',
    image: oil,
    description:
      'pentru toate tipurile de păr. Oferă strălucire părului, lăsându-l moale și mătăsos',
    additional: 'Привет Россия',
    volume: [
      { size: '30ml', price: 420 },
      { size: '100ml', price: 800 },
    ],
  },
  {
    id: 5,
    name: 'CAPIXYL SAMPOO',
    category: 'Hair',
    image: capyxal,
    description:
      'Șamponul este destinat prevenirii căderii părului și reducerii excesului de sebum. Poate fi folosit pentru spălări frecvente.',
    additional: 'Привет Россия',
    volume: [
      { size: '200ml', price: 450 },
      { size: '500ml', price: 810 },
      { size: '1000ml', price: 1230 },
    ],
  },
  {
    id: 6,
    name: 'SER TRATAMENT CAPIXYL',
    category: 'Hair',
    image: ser,
    description: 'Un tratament zilnic care previne căderea și ruperea părului',
    additional: 'Привет Россия',
    volume: [{ size: '30ml', price: 750 }],
  },
  {
    id: 7,
    name: 'CONDITIONER ENERGIZING',
    category: 'Hair',
    image: pr,
    description: '',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 450 }],
  },
  {
    id: 8,
    name: 'ARMONIA SHAMPOO',
    category: 'Hair',
    image: armonia,
    description:
      'Șampon Dermo-Echilibrant pentru îngrijirea tuturor tipurilor de păr, cu Formulă Hipoalergenică..',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 550 },
      { size: '1000ml', price: 1230 },
    ],
  },
  {
    id: 9,
    name: 'ARMONIA SCRUB',
    category: 'Hair',
    image: armoniascr,
    description:
      'Tratament purificator care eliberează scalpul de excesul de sebum',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 680 }],
  },
  {
    id: 10,
    name: 'MASS SOLUTION SHAMPOO',
    category: 'Hair',
    image: mass,
    description:
      'Șamponul asigură o curățare blândă adâncă și face părul mai elastic. Destinat firului subțire și fragil.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 590 },
      { size: '1000ml', price: 1800 },
    ],
  },
  {
    id: 11,
    name: 'MASS SOLUTION SCALP REVITALIZER',
    category: 'Hair',
    image: masssol,
    description:
      'Serumul Mass Solution Revitalizer tratament pentru scalp pe bază de îngredienți activi, care au ca obiectiv ancorarea mai puternică a firului de păr în rădăcină.',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 825 }],
  },
  {
    id: 12,
    name: 'FILLER THERAPY RESTORATIVE SHAMPOO',
    category: 'Hair',
    image: filler,
    description:
      'Șampon regenerant pentru păr uscat si deteriorat. Hidratează părul in profunzime. Îmbogățit prin Collagen, Keratină si Elastină.',
    additional: 'Привет Россия',
    volume: [
      { size: '250ml', price: 530 },
      { size: '1000ml', price: 1230 },
    ],
  },
  {
    id: 13,
    name: 'FILLER THERAPY RESTORATIVE CONDITIONER',
    category: 'Hair',
    image: rest,
    description:
      'Condiționer pentru părul uscat și deteriorat. Restructurează și hrănește părul uscat făra să îl încarce. Prevene despicarea vârfurilor.',
    additional: 'Привет Россия',
    volume: [
      { size: '150ml', price: 550 },
      { size: '1000ml', price: 1230 },
    ],
  },
  {
    id: 14,
    name: 'MASCA NASHI FILLER THERAPY LIFTING',
    category: 'Hair',
    image: g1,
    description:
      'Masca pentru par cu efect de lifting, elimina imperfectiunile pentru un rezultat imediat de strălucire si disciplină.',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 750 }],
  },
  {
    id: 15,
    name: 'DEEP INFUSION MASK',
    category: 'Hair',
    image: deep,
    description:
      'Masc este potrivită pentru păr cu structură ondulată, slăbit sau pentru părul care are nevoie de hidratare intensă.',
    additional: 'Привет Россия',
    volume: [
      { size: '150ml', price: 600 },
      { size: '250ml', price: 870 },
      { size: '500ml', price: 1280 },
    ],
  },
  {
    id: 16,
    name: 'FIOLA FILLER THERAPY LIFTING 1X8 ML',
    category: 'Hair',
    image: resti,
    description:
      'Elixir-tratament reconstructiv cu efect imediat pentru par uscat si deteriorat.',
    additional: 'Привет Россия',
    volume: [{ size: '8ml', price: 110 }],
  },
  {
    id: 17,
    name: 'FIOLA FILLER THERAPY LIFTING 24X8 ML',
    category: 'Hair',
    image: filler2,
    description:
      'Elixir-tratament reconstructiv cu efect imediat pentru par uscat si deteriorat.',
    additional: 'Привет Россия',
    volume: [{ size: '24*8ml', price: 2100 }],
  },
  {
    id: 18,
    name: 'BLONDY JOI PURPLE SHAMPOO',
    category: 'Hair',
    image: blondy,
    description: 'Șampon creat pentru a înlătura tonurile de galben.',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 540 }],
  },
  {
    id: 19,
    name: 'BLONDY JOY PURPLE CONDITIONER',
    category: 'Hair',
    image: purple,
    description: 'Conditioner pentru menținerea frumuseții parului blond.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 500 }],
  },
  {
    id: 20,
    name: 'L’ESSENZA NASHI ARGAN',
    category: 'Body',
    image: ds,
    description: 'Conditioner pentru menținerea frumuseții parului blond.',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 1120 }],
  },
  {
    id: 21,
    name: 'LOVE HAIR MIST',
    category: 'Body',
    image: love,
    description:
      'Parfum pentru păr în formă de spray.Păstrează părul moale, matăsos și strălucitor. Protejează de efectele negative ale mediului.',
    additional: 'Привет Россия',
    volume: [{ size: '20ml', price: 300 }],
  },
  {
    id: 22,
    name: 'GEL DE DUȘ',
    category: 'Body',
    image: wqe,
    description: 'Hidratează în profunzime și curăță delicat',
    additional: 'Привет Россия',
    volume: [{ size: '300ml', price: 480 }],
  },
  {
    id: 23,
    name: 'SHOWER OIL',
    category: 'Body',
    image: asd,
    description: 'Uleiul de duș mătăsos pentru SPA-ul tău personal!',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 525 }],
  },
  {
    id: 24,
    name: 'BODY SCRUB',
    category: 'Body',
    image: scr,
    description: 'Scrab pentru corp pentru o piele catifelată și hidratată',
    additional: 'Привет Россия',
    volume: [{ size: '250gr', price: 750 }],
  },
  {
    id: 25,
    name: 'DRY OIL',
    category: 'Body',
    image: dry,
    description: 'Ulei pentru o piele uscată, tonifică și oferă elasticitate!',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 825 }],
  },
  {
    id: 26,
    name: 'DAILY BODY CREAM',
    category: 'Body',
    image: day,
    description: 'Cremă de corp pentru o piele tonifiată și hidratată!',
    additional: 'Привет Россия',
    volume: [{ size: '200ml', price: 525 }],
  },
  {
    id: 27,
    name: 'SCENTED HAND WASH',
    category: 'Body',
    image: sce,
    description: 'Săpun pentru mâni cu uleiuri esențiale',
    additional: 'Привет Россия',
    volume: [{ size: '500ml', price: 600 }],
  },
  {
    id: 29,
    name: 'DRY HAND BALM 30 ML',
    category: 'Body',
    image: bal,
    description:
      'Crema de maini non-grasa, imbogatita cu ulei organic de argan',
    additional: 'Привет Россия',
    volume: [{ size: '30ml', price: 200 }],
  },

  {
    id: 31,
    name: 'DRY HAND BALM 500 ML',
    category: 'Body',
    image: han,
    description:
      'Cremă de mâini non-grasă, îmbogațită cu ulei organic de argan',
    additional: 'Привет Россия',
    volume: [{ size: '500ml', price: 1050 }],
  },
  {
    id: 32,
    name: 'BALSAM DE BUZE',
    category: 'Body',
    image: lip,
    description: 'Balsam de buze in stick pentru buze moi si protejate',
    additional: 'Привет Россия',
    volume: [{ size: '12ml', price: 200 }],
  },
  {
    id: 33,
    name: 'GEL DE FAȚĂ',
    category: 'Body',
    image: oel,
    description: 'Curăță, îndepărtează machiajul și îți purifică fața',
    additional: 'Привет Россия',
    volume: [{ size: '100ml', price: 530 }],
  },
  {
    id: 34,
    name: 'CURLY MAKER MOISTURIZING',
    category: 'Styling',
    image: cur,
    description: 'Cremă pentru hidratarea și formarea buclelor naturale.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 450 }],
  },
  {
    id: 35,
    name: 'NASHI ARGAN STYLE SHIMMER AND SHINE',
    category: 'Styling',
    image: images,
    description:
      'Spray pentru intesificarea strălucirii părului, cu note de parfum care îți vor învălui simțurile.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 470 }],
  },
  {
    id: 36,
    name: 'SEA SALT SPRAY',
    category: 'Styling',
    image: sadd,
    description:
      'Spray-ul pentru volum creează valuri naturale pentru o coafură "de plajă”.',
    additional: 'Привет Россия',
    volume: [{ size: '150ml', price: 510 }],
  },
  {
    id: 37,
    name: 'MENS DALY ENERGIZING SHAMPOO',
    category: 'Men',
    image: ener,
    description: 'Sampon intătește și îngroașe firul de păr',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 525 }],
  },
  {
    id: 38,
    name: 'SHOWER GEL',
    category: 'Men',
    image: geler,
    description: 'gel de duș',
    additional: 'Привет Россия',
    volume: [{ size: '250ml', price: 405 }],
  },
  {
    id: 39,
    name: 'OIL HAIR&BEARD',
    category: 'Men',
    image: saw,
    description: 'ulei pentru barbă și păr',
    additional: 'Привет Россия',
    volume: [{ size: '50ml', price: 425 }],
  },
  {
    id: 40,
    name: 'SET DE VARĂ',
    category: 'BOX SETS',
    image: vara,
    description:
      'Nashi Loves Beach: totul pentru frumusețea și protecția părului tău sub soare',
    additional: 'Привет Россия',
    volume: [{ size: 'all', price: 1630 }],
  },
  {
    id: 41,
    name: 'TRY ME SET',
    category: 'BOX SETS',
    image: cvv,
    description:
      'Nashi Loves Beach: totul pentru frumusețea și protecția părului tău sub soare',
    additional: 'Привет Россия',
    volume: [{ size: 'all', price: 750 }],
  },
  {
    id: 42,
    name: 'HAIR GIFT BOX',
    category: 'BOX SETS',
    image: ksiu,
    description:
      'Nashi Loves Beach: totul pentru frumusețea și protecția părului tău sub soare',
    additional: 'Привет Россия',
    volume: [{ size: 'all', price: 1350 }],
  },
]

export default NashiProducts
