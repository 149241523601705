import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import NashiLogo1 from '../assets/Nashi.png'
import DavinesLogo1 from '../assets/davinesLogo.jpg'
import Slide1 from '../assets/SLIDE8.PNG'
import Slide2 from '../assets/SLIDE228.PNG'
import Slide3 from '../assets/SLIDE15.PNG'
import Slide4 from '../assets/SLIDE12.PNG'
import Slide5 from '../assets/SLIDE19.PNG'

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slides, setSlides] = useState([Slide1, Slide2, Slide3, Slide4, Slide5])

  useEffect(() => {
    // Определение ширины экрана и установка слайдов
    const updateSlides = () => {
      const width = window.innerWidth
      if (width <= 300) {
        setSlides([Slide1, Slide3, Slide4, Slide5]) // Ширина <= 399px
      } else if (width <= 450) {
        setSlides([Slide2, Slide3, Slide4, Slide5]) // Ширина 400px - 580px
      } else if (width < 970) {
        setSlides([Slide3, Slide4, Slide5]) // Ширина 581px - 969px
      } else {
        setSlides([Slide1, Slide2, Slide3, Slide4, Slide5]) // Ширина >= 970px
      }
    }

    // Вызов функции при первом рендере и при изменении размера экрана
    updateSlides()
    window.addEventListener('resize', updateSlides)

    return () => window.removeEventListener('resize', updateSlides)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)
    }, 2000)
    return () => clearInterval(interval)
  }, [slides.length])

  return (
    <div>
      <div className="slideshow-container">
        <img
          src={slides[currentSlide]}
          alt="Slideshow"
          className="slideshow-image"
        />
      </div>
      <div className="home-container">
        <Link to="/Davines">
          <img src={DavinesLogo1} alt="Логотип Davines" />
        </Link>
        <Link to="/Nashi">
          <img src={NashiLogo1} alt="Логотип Nashi" />
        </Link>
      </div>
      <p className="description-text">
        Produsele sunt bogate in ingrediente naturale pure, care ingrijesc
        intens parul, restabilind frumusetea si rezistenta sa natural. Nu contin
        parabeni si nici sulfati!
      </p>
    </div>
  )
}

export default Home
